
import { Component, Prop, Vue } from "vue-property-decorator";

/** Lecco Latte table action button */
@Component
export default class TableActions extends Vue {
  /** Sets the action button type in order to set the button icon, color and tooltip */
  @Prop({ type: String, required: false })
  private type: string;

  private color: string;
  private icon: string;
  private tooltip: string;

  created(): void {
    switch (this.type.toLowerCase()) {
      case "edit":
        this.icon = "mdi-pencil";
        this.color = "secondary darken-1";
        this.tooltip = "Modifica";
        break;
      case "view":
        this.icon = "mdi-magnify";
        this.color = "secondary darken-1";
        this.tooltip = "Dettagli";
        break;
      case "delete":
        this.icon = "mdi-delete";
        this.color = "secondary darken-1";
        this.tooltip = "Elimina";
        break;
      case "user-off":
        this.icon = "mdi-account-off";
        this.color = "secondary darken-1";
        this.tooltip = "Disabilita utente";
        break;
      case "cancel":
        this.icon = "mdi-close-thick";
        this.color = "secondary darken-1";
        this.tooltip = "Annulla ordine";
        break;
      case "mail":
        this.icon = "mdi-email";
        this.color = "secondary darken-1";
        this.tooltip = "Email";
        break;
      case "new-order":
        this.icon = "mdi-cart-plus";
        this.color = "secondary darken-1";
        this.tooltip = "Nuovo ordine";
        break;
      case "re-order":
        this.icon = "mdi-cart-arrow-down";
        this.color = "secondary darken-1";
        this.tooltip = "Riordina";
        break;
      case "confirm-order":
        this.icon = "mdi-check-bold";
        this.color = "secondary darken-1";
        this.tooltip = "Conferma tutto l'ordine";
        break;
      case "reject-order":
        this.icon = "mdi-clipboard-text-off-outline";
        this.color = "secondary darken-1";
        this.tooltip = "Respingi tutto l'ordine";
        break;
      case "confirm-customer":
        this.icon = "mdi-account-check";
        this.color = "secondary darken-1";
        this.tooltip = "Conferma account";
        break;
      default:
        this.icon = "";
        this.color = "";
        this.tooltip = "";
    }
  }
}
