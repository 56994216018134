var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{attrs:{"loading":_vm.items.loading,"headers":_vm.items.headers,"items":_vm.items.values,"search":_vm.items.search,"items-per-page":50,"footer-props":{
    itemsPerPageOptions: [50, 100, 200],
    itemsPerPageText: 'Elementi per pagina',
    pageText: '{0}-{1} di {2}',
  },"header-props":{
    sortByText: 'Ordina per',
  },"item-key":_vm.items.key ? _vm.items.key : 'id',"loading-text":"Caricamento in corso ...","no-data-text":"Nessun elemento trovato","no-results-text":"Nessun elemento trovato","hide-default-footer":_vm.hideDefaultFooter,"fixed-header":_vm.hideDefaultFooter},on:{"dblclick:row":_vm.rowClick},scopedSlots:_vm._u([_vm._l((_vm.items.headers),function(header,idx){return {key:`item.${header.value}`,fn:function(slotProps){return [(
        header.cellType === _vm.CellType.ACTION &&
        ((header.customProp === 'isDeleted' &&
          !slotProps.item[header.customProp]) ||
          !header.customProp)
      )?_c('div',{key:`${slotProps.index}_${idx}`},_vm._l((_vm.items.actions),function(action){return _c('table-actions',{key:action.id,attrs:{"type":action.name},on:{"click":function($event){return _vm.doAction(action.name, slotProps)}}})}),1):_vm._e(),(
        header.cellType === _vm.CellType.ACTION &&
        header.customProp === 'isDeleted' &&
        slotProps.item[header.customProp]
      )?_c('div',{key:`${slotProps.index}_${idx}`},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"mr-5"},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"secondary darken-1","x-small":""},on:{"click":function($event){return _vm.$emit('reactiveUser', slotProps.item)}}},[_c('v-icon',[_vm._v("mdi-account-reactivate")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Riattiva utente")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"mr-5"},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"secondary darken-1","x-small":""},on:{"click":function($event){return _vm.$emit('deletePermanently', slotProps.item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Elimina permanentemente")])])],1):_vm._e(),(
        header.cellType === _vm.CellType.ACTION &&
        header.customProp === 'isConfirmed' &&
        slotProps.item[header.customProp]
      )?_c('div',{key:`${slotProps.index}_${idx}`},[(slotProps.item['isCanceled'])?_vm._l((_vm.getOrderManagementActions(
            _vm.items.actions,
            slotProps.item['userID'],
            'cancel',
            slotProps.item['createdByAdmin']
          )),function(action){return _c('table-actions',{key:action.id,attrs:{"type":action.name},on:{"click":function($event){return _vm.doAction(action.name, slotProps)}}})}):(
          !slotProps.item['isCanceled'] &&
          slotProps.item['isDefinitevelyConfirmed']
        )?_vm._l((_vm.getOrderManagementActions(
            _vm.items.actions,
            slotProps.item['userID'],
            'confirmation',
            slotProps.item['createdByAdmin']
          )),function(action){return _c('table-actions',{key:action.id,attrs:{"type":action.name},on:{"click":function($event){return _vm.doAction(action.name, slotProps)}}})}):_vm._l((_vm.getOrderManagementActions(
            _vm.items.actions,
            slotProps.item['userID'],
            'toBeConfirmed',
            slotProps.item['createdByAdmin']
          )),function(action){return _c('table-actions',{key:action.id,attrs:{"type":action.name},on:{"click":function($event){return _vm.doAction(action.name, slotProps)}}})})],2):_vm._e(),(
        header.cellType === _vm.CellType.ACTION &&
        header.customProp === 'isConfirmed' &&
        !slotProps.item[header.customProp]
      )?_c('div',{key:`${slotProps.index}_${idx}`},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"mr-5"},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"secondary darken-1","x-small":""}},[_c('v-icon',[_vm._v("mdi-cart-arrow-down")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("L'ordine è nel carrello ma l'utente non l'ha ancora inviato")])])],1):_vm._e(),(header.cellType === _vm.CellType.SWITCH)?_c('div',{key:`${slotProps.index}_${idx}`,style:(_vm.$vuetify.breakpoint.mdAndDown
          ? 'max-width: 130px !important'
          : 'width: 125px')},[_c('v-row',{staticStyle:{"flex-wrap":"nowrap !important"},attrs:{"dense":"","no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pr-1"},[_vm._v(" "+_vm._s(header.customText ? header.customText : "Abilitato")+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{attrs:{"color":"primary darken-2"},on:{"change":function($event){return _vm.$emit('switched', slotProps.item)}},model:{value:(slotProps.item[header.value]),callback:function ($$v) {_vm.$set(slotProps.item, header.value, $$v)},expression:"slotProps.item[header.value]"}})],1)],1)],1):_vm._e(),(header.cellType === _vm.CellType.LIST)?_c('div',{key:`${slotProps.index}_${idx}`},[(slotProps.item[header.value].length <= 3)?[_c('ul',_vm._l((slotProps.item[header.value]),function(el,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(el[header.customProp])+" ")])}),0)]:[_c('ul',[_c('li',[_vm._v(" "+_vm._s(slotProps.item[header.value][0][header.customProp])+" ")]),_c('li',[_vm._v(" "+_vm._s(slotProps.item[header.value][1][header.customProp])+" ")])]),_c('ul',[_vm._v(" ( +"+_vm._s(slotProps.item[header.value].length - 2)+" ) ")])]],2):_vm._e(),(
        header.cellType === _vm.CellType.DEPENDENCYLIST &&
        slotProps.item[header.dependencyProp] == header.dependencyPropValue
      )?_c('div',{key:`${slotProps.index}_${idx}`},[(slotProps.item[header.value].length <= 3)?[_c('ul',_vm._l((slotProps.item[header.value]),function(el,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(el[header.customProp])+" ")])}),0)]:[_c('ul',[_c('li',[_vm._v(" "+_vm._s(slotProps.item[header.value][0][header.customProp])+" ")]),_c('li',[_vm._v(" "+_vm._s(slotProps.item[header.value][1][header.customProp])+" ")])]),_c('ul',[_vm._v(" ( +"+_vm._s(slotProps.item[header.value].length - 2)+" ) ")])]],2):_vm._e(),(header.cellType === _vm.CellType.DATE)?_c('div',{key:`${slotProps.index}_${idx}`},[(slotProps.item[header.customProp] == true || !header.customProp)?[_vm._v(" "+_vm._s(new Date(slotProps.item[header.value]).toFormat("dd/MM/yyyy"))+" ")]:[_vm._v(" -- ")]],2):_vm._e(),(header.cellType === _vm.CellType.PRICE)?_c('div',{key:`${slotProps.index}_${idx}`},[_vm._v(" "+_vm._s(slotProps.item[header.value] ? Number(slotProps.item[header.value]).toFixed(2) : "--")+" € ")]):_vm._e(),(header.cellType === _vm.CellType.PRICEWITHPROMO)?_c('div',{key:`${slotProps.index}_${idx}`},[(
          slotProps.item[header.condition] && slotProps.item[header.value]
        )?[_c('strong',{staticStyle:{"font-size":"18px !important"}},[_vm._v(" "+_vm._s(Number(slotProps.item[header.value]).toFixed(2))+" € ")]),_vm._v("  "),_c('del',{staticStyle:{"font-size":"16px !important"}},[_vm._v(" "+_vm._s(Number(slotProps.item[header.customProp]).toFixed(2))+" €")])]:(
          !slotProps.item[header.condition] && slotProps.item[header.value]
        )?[_c('strong',{staticStyle:{"font-size":"18px !important"}},[_vm._v(" "+_vm._s(Number(slotProps.item[header.value]).toFixed(2))+" € ")])]:[_c('span',{staticStyle:{"font-size":"18px !important"}},[_vm._v(" -- € ")])]],2):_vm._e(),(header.cellType === _vm.CellType.STATUS)?_c('div',{key:`${slotProps.index}_${idx}`},[(header.value == 'isConfirmed')?[(slotProps.item[header.customProp])?[(slotProps.item['isCanceledByAdmin'])?_c('span',[_vm._v(" Respinto "),_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-clipboard-text-off-outline")])],1):_c('span',[_vm._v(" Annullato "),_c('v-icon',{attrs:{"color":"error darken-2","small":""}},[_vm._v("mdi-close-circle-outline")])],1)]:(
            !slotProps.item[header.customProp] &&
            !slotProps.item['isDefinitevelyConfirmed']
          )?[(slotProps.item[header.value])?_c('span',[_vm._v(" Da confermare "),_c('v-icon',{attrs:{"color":"accent","small":""}},[_vm._v("mdi-clipboard-clock-outline")])],1):_c('span',[_vm._v(" In corso "),_c('v-icon',{attrs:{"color":"accent darken-2","small":""}},[_vm._v("mdi-cart-arrow-down")])],1)]:(
            !slotProps.item[header.customProp] &&
            slotProps.item['isDefinitevelyConfirmed']
          )?[(slotProps.item[header.value])?_c('span',[_vm._v(" Confermato "),_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("mdi-check-circle-outline")])],1):_vm._e()]:_vm._e()]:(header.customProp == 'activeUser')?[(!slotProps.item[header.value])?_c('span',[_vm._v(" Attivo "),_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("mdi-check-circle-outline")])],1):_c('span',[_vm._v(" Disabilitato "),_c('v-icon',{attrs:{"color":"accent","small":""}},[_vm._v("mdi-alert-circle-outline")])],1)]:[_vm._v(" "+_vm._s(slotProps.item[header.value])+" ")]],2):_vm._e(),(header.cellType === _vm.CellType.CUSTOMERNAME)?_c('div',{key:`${slotProps.index}_${idx}`,staticStyle:{"color":"black !important","caret-color":"black !important","font-weight":"bold !important"}},[_vm._v(" "+_vm._s(slotProps.item[header.customProp] ? slotProps.item[header.customProp] : slotProps.item[header.value] ? slotProps.item[header.value] : "--")+" ")]):_vm._e(),(
        header.cellType === _vm.CellType.DEFAULT &&
        header.isPrimary &&
        slotProps.item[header.value]
      )?_c('div',{key:`${slotProps.index}_${idx}`,staticStyle:{"color":"black !important","caret-color":"black !important","font-weight":"bold !important"},domProps:{"innerHTML":slotProps.item[header.value]}}):_vm._e(),(header.cellType === _vm.CellType.BOOLEAN)?_c('div',{key:`${slotProps.index}_${idx}`},[(slotProps.item[header.value] == true)?_c('v-icon',{attrs:{"color":"success base"}},[_vm._v(" mdi-check ")]):(slotProps.item[header.value] == false)?_c('v-icon',{attrs:{"color":"error base"}},[_vm._v(" mdi-close ")]):_c('div',[_vm._v("--")])],1):_vm._e(),(header.cellType === _vm.CellType.BOOLICON)?_c('div',{key:`${slotProps.index}_${idx}`},[(slotProps.item[header.value] == true)?_c('div',{domProps:{"innerHTML":_vm._s(header.svg)}}):_c('div',[_vm._v("--")])]):_vm._e(),(
        header.cellType === _vm.CellType.DEFAULT &&
        !header.isPrimary &&
        slotProps.item[header.value]
      )?_c('div',{key:`${slotProps.index}_${idx}`,domProps:{"innerHTML":slotProps.item[header.value]}}):_vm._e(),(
        header.cellType === _vm.CellType.DEFAULT && !slotProps.item[header.value]
      )?_c('div',{key:`${slotProps.index}_${idx}`},[_vm._v(" -- ")]):_vm._e()]}}})],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }