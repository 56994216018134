
import { Component, Prop, Vue } from "vue-property-decorator";
import { ClickObj } from "@/models/utils/click-obj.interface";
import { DataTable } from "@/models/utils/datatable.interface";
import TableActions from "@/components/TableActions.vue";
import { Action, TableAction } from "@/models/utils/table-action.interface";

@Component({
  components: {
    TableActions,
  },
})
export default class LLDataTable extends Vue {
  /** Sets the data table items */
  @Prop({ required: true }) private items: DataTable<unknown>;
  /** Sets the data table footer */
  @Prop({ required: false, default: false, type: Boolean })
  private hideDefaultFooter: boolean;

  rowClick(event: MouseEvent, obj: ClickObj): void {
    this.$emit("dblclick", obj.item);
  }

  private getOrderManagementActions(
    actions: TableAction[],
    userID: number,
    type: string,
    createdByAdmin: boolean
  ): TableAction[] {
    if (type == "cancel") {
      return actions.filter(
        (a) =>
          a.id == Action.VIEW || a.id == Action.MAIL || a.id == Action.REORDER
      );
    } else if (type == "toBeConfirmed") {
      if (userID == null && createdByAdmin == false) {
        return actions.filter(
          (a) =>
            a.id == Action.VIEW ||
            a.id == Action.CANCEL ||
            a.id == Action.MAIL ||
            a.id == Action.REORDER ||
            a.id == Action.REJECTORDER
        );
      }
      return actions.filter(
        (a) =>
          a.id == Action.VIEW ||
          a.id == Action.CANCEL ||
          a.id == Action.MAIL ||
          a.id == Action.REORDER ||
          a.id == Action.CONFIRMORDER ||
          a.id == Action.REJECTORDER
      );
    } else {
      if (userID == null) {
        return actions.filter(
          (a) =>
            a.id == Action.VIEW ||
            a.id == Action.CANCEL ||
            a.id == Action.MAIL ||
            a.id == Action.REORDER
        );
      }
      return actions.filter(
        (a) =>
          a.id == Action.VIEW ||
          a.id == Action.CANCEL ||
          a.id == Action.MAIL ||
          a.id == Action.REORDER
      );
    }
  }

  doAction(actionName: string, obj: ClickObj): void {
    this.$emit(actionName, obj.item);
  }
}
